import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDribbble, faFacebook, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'
import useSiteMetadata from "../hooks/use-site-metadata";

type Props = {}

const Footer: React.FC<Props> = () => {
  const metadata = useSiteMetadata()
  const {facebook, twitter, dribble, instagram} = metadata.social
  return (
    <footer className="bg-gray-900">
      <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="flex justify-center mt-4 space-x-6">
          {facebook && <a href={facebook} className="text-gray-400 hover:text-gray-500">
            <span className="sr-only">Facebook</span>
            <FontAwesomeIcon icon={faFacebook} size="lg"/>
          </a>}
          {instagram && <a href={instagram} className="text-gray-400 hover:text-gray-500">
            <span className="sr-only">Instagram</span>
            <FontAwesomeIcon icon={faInstagram} size="lg"/>
          </a>}
          {twitter && <a href={twitter} className="text-gray-400 hover:text-gray-500">
            <span className="sr-only">Twitter</span>
            <FontAwesomeIcon icon={faTwitter} size="lg"/>
          </a>}
          {dribble && <a href={dribble} className="text-gray-400 hover:text-gray-500">
            <span className="sr-only">Dribbble</span>
            <FontAwesomeIcon icon={faDribbble} size="lg"/>
          </a>}
        </div>
        <p className="mt-12 text-base leading-6 text-center text-gray-400">
          © 2021 tumbgames. All rights reserved.
        </p>
        <div className="flex flex-wrap justify-center space-x-4">
          <a href="/terms-of-use" className="text-white hover:text-yellow-600">Terms of Use</a>
          <a href="/privacy-policy" className="text-white hover:text-yellow-600">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
