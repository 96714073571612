import {NavItem} from "../components/Navbar";

type NavName = '/' | 'blog' | 'about' | '';

const navItems: NavItem[] = [
  {name: 'Games', id: "/", href: '/', active: false},
  {name: 'Blog', id: "blog", href: '/blog', active: false},
  {name: 'About', id: "about", href: '/about', active: false},
]

export function createNavItems(args: { current: NavName }): NavItem[] {
  const {current} = args;
  return navItems.map(value => {
    return {
      name: value.name,
      href: value.href,
      active: value.id === current,
    } as NavItem
  })
}
