import React from "react";

type Props = {}

const LayoutWrapper: React.FC<Props> = ({children}) => {
  return (
    <div className="flex flex-col bg-white font-sans leading-normal tracking-normal min-h-screen">
      {children}
    </div>
  );
};

export default LayoutWrapper
