import React from "react";
import {classNames} from "../utils";

type Props = {
  className?: string
}

const PageWrapper: React.FC<Props> = ({className = "", children}) => {
  return (
    <div className={classNames("container px-4 md:px-0 max-w-6xl mx-auto pt-16", className)}>
      <div className="mx-0 sm:mx-6 ">
        {children}
      </div>
    </div>
  );
};

export default PageWrapper
