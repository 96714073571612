import React from "react";
import Navbar, {NavItem} from "./Navbar";
import SEO from "./seo";
import Footer from "./Footer";
import Wrapper from "./LayoutWrapper";

type Props = {
  navItems: NavItem[]
}

const Layout: React.FC<Props> = ({navItems, children}) => {
  return (
    <React.Fragment>
      <SEO/>
      <Wrapper>
        <Navbar items={navItems}/>
        <main className="flex-grow">
          {children}
        </main>
        <Footer/>
      </Wrapper>
    </React.Fragment>
  );
}
export default Layout;
